import { lazy } from "react";

// project imports
import MainLayout from "../layout/MainLayout";
// import Users from '../views/users';
import Loadable from "./../components/Loadable";
import Categories from "../views/pages/categories";
import SubCategories from "../views/pages/subCategories";
import Pos from "../views/POS";
import Group from "../views/management/groups";
import Banner from "../views/management/banners";
import Items from "../views/items";
import Pins from "../views/pins";
import Notification from "../views/notification";
import Profile from "../views/users/profile";
import Operation from "../views/operation";
import Wallets from "../views/wallets";
import Transactions from "../views/wallets/transactions";
import RequestPoints from "../views/request-points";
import RepDetails from "../views/reports/repDetails";
import OrdersReport from "../views/reports/orders";
import Supplier from "../views/supplier-bills/suppliers";
import SupplierTransactions from "../views/supplier-bills/suppliers/supplierTransactions";
import Bills from "../views/supplier-bills/bills";
import Expense from "../views/expense";
import TotalRevenue from "../views/reports/totalRevenue";
import CashReport from "../views/reports/cash";
import Provinces from "../views/management/provinces/province";
import TotalsReport from "../views/reports/totals";
import StockReport from "../views/reports/stock";
import Products from "../views/platform/products";
import ProductSupplier from "../views/platform/productsSupplier";
import ProductsOrders from "../views/platform/productOrders";
import ProductSupplierTransactions from "../views/platform/productsSupplier/transactions";
import ExternalRevenue from "../views/externalRevenue";
import PlatformPayments from "../views/reports/platformPayments";
import OrdersAndTransferBalance from "../views/orders";
import OperationTransfer from "../views/balanceTransfer/operationTransfer";
import SimCard from "../views/balanceTransfer/simCard";
import Sms from "../views/balanceTransfer/sms";
import PosSalesPerWeekChart from "../views/ordersStatistics/posOrdersPerWeek";
import RepSalesPerWeekChart from "../views/ordersStatistics/repOrdersPerWeek";
import Tickets from "../views/tickets";
import TicketDetails from "../views/tickets/home/details";
import Cards from "../views/payments/cards";
import OrderRechargeCards from "../views/payments/orderRechargeCards";
import IssuedCard from "../views/payments/issuedCard";
import Investors from "../views/supplier-bills/investors";
import AsiaBills from "../views/asiaBills";
import EpaymentTransactions from "../views/payments/transactions";
import JCategories from "../views/jomla/category";
import JItems from "../views/jomla/items";
import JSupplier from "../views/jomla/supplier";
import PosLayout from "../layout/PosLayout";
import JInvoices from "../views/jomla/Invoice";
import JOrder from "../views/jomla/orders";
import ExternalOrder from "../views/pos_dashboard/externalOrders";
import JOrderDetails from "../views/jomla/orders/home/table/orderDetails";
import JReport from "../views/jomla/reports";
import JSafe from "../views/jomla/safe";
import JTransaction from "../views/jomla/supplier/supplierTransactions";
import JPos from "../views/jomla/pos";
import Settings from "../views/settings";
import PosType from "../views/management/posType";
import PosField from "../views/management/posFields";
import JGroup from "../views/jomla/jGroup";
import JExpense from "../views/jomla/expense";

// dashboard routing
const Home = Loadable(lazy(() => import("../views/home")));
const Users = Loadable(lazy(() => import("../views/users")));
const NonePos = Loadable(lazy(() => import("../views/nonePos")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = [
  {
    path: "/app",
    element: <MainLayout />,
    children: [
      {
        path: "/app",
        element: <Home />,
      },
      {
        path: "/app/statistics/",
        children: [
          {
            path: "/app/statistics/pos_orders",
            element: <PosSalesPerWeekChart />,
          },
          {
            path: "/app/statistics/rep_orders",
            element: <RepSalesPerWeekChart />,
          },
        ],
      },
      {
        path: "/app/users",
        element: <Users />,
      },
      {
        path: "/app/user/Profile",
        element: <Profile />,
      },
      {
        path: "/app/pos",
        element: <Pos />,
      },
      {
        path: "/app/request_points",
        element: <RequestPoints />,
      },
      {
        path: "/app/none_pos",
        element: <NonePos />,
      },
      {
        path: "/app/items",
        element: <Items />,
      },
      {
        path: "/app/suppliers&bills/",
        children: [
          {
            path: "/app/suppliers&bills/suppliers",
            element: <Supplier />,
          },
          {
            path: "/app/suppliers&bills/suppliers/transactions/:supplier_id",
            element: <SupplierTransactions />,
          },
          {
            path: "/app/suppliers&bills/bills",
            element: <Bills />,
          },
          {
            path: "/app/suppliers&bills/Investors",
            element: <Investors />,
          },
        ],
      },
      {
        path: "/app/pins",
        children: [
          {
            path: "/app/pins/pins/:item_id",
            element: <Pins />,
          },
          {
            path: "/app/pins/pins",
            element: <Pins />,
          },
          {
            path: "/app/pins/operations",
            element: <Operation />,
          },
        ],
      },
      {
        path: "/app/notification",
        element: <Notification />,
      },
      {
        path: "/app/orders",
        element: <OrdersAndTransferBalance />,
      },
      {
        path: "/app/expense",
        element: <Expense />,
      },
      {
        path: "/app/external_revenue",
        element: <ExternalRevenue />,
      },
      {
        path: "/app/asia_bills",
        element: <AsiaBills />,
      },
      // {
      //   path: "/app/sim_card",
      //   element: <SimCard />,
      // },
      // {
      //   path: "/app/operation_transfer",
      //   element: <OperationTransfer />,
      // },
      {
        path: "/app/sim_card_transfer",
        children: [
          {
            path: "/app/sim_card_transfer/sim_card",
            element: <SimCard />,
          },
          {
            path: "/app/sim_card_transfer/operation_transfer",
            element: <OperationTransfer />,
          },
          {
            path: "/app/sim_card_transfer/received_sms",
            element: <Sms />,
          },
        ],
      },
      {
        path: "/app/wallets",
        children: [
          {
            path: "/app/wallets",
            element: <Wallets />,
          },
          {
            path: "/app/wallets/transactions/:user_id",
            element: <Transactions />,
          },
        ],
      },
      {
        path: "/app/pages",
        children: [
          {
            path: "/app/pages/categories",
            element: <Categories />,
          },
          {
            path: "/app/pages/sub_categories",
            element: <SubCategories />,
          },
        ],
      },
      {
        path: "/app/management",
        children: [
          {
            path: "/app/management/banner",
            element: <Banner />,
          },
          {
            path: "/app/management/groups",
            element: <Group />,
          },
          {
            path: "/app/management/provinces",
            element: <Provinces />,
          },
          {
            path: "/app/management/pos_types",
            element: <PosType />,
          },
          {
            path: "/app/management/pos_field",
            element: <PosField />,
          },
        ],
      },
      {
        path: "/app/reports",
        children: [
          {
            path: "/app/reports/representative_details",
            element: <RepDetails />,
          },
          {
            path: "/app/reports/orders",
            element: <OrdersReport />,
          },
          {
            path: "/app/reports/total_revenue",
            element: <TotalRevenue />,
          },
          {
            path: "/app/reports/cash",
            element: <CashReport />,
          },
          {
            path: "/app/reports/totals",
            element: <TotalsReport />,
          },
          {
            path: "/app/reports/stock",
            element: <StockReport />,
          },
          {
            path: "/app/reports/platform_payments",
            element: <PlatformPayments />,
          },
        ],
      },
      {
        path: "/app/platform",
        children: [
          {
            path: "/app/platform/products",
            element: <Products />,
          },
          // {
          //   path: '/app/platform/product_supplier',
          //   element: < ProductSupplier />
          // },
          {
            path: "/app/platform/product_supplier",
            children: [
              {
                path: "/app/platform/product_supplier",
                element: <ProductSupplier />,
              },
              {
                path: "/app/platform/product_supplier/transactions/:product_supplier_id",
                element: <ProductSupplierTransactions />,
              },
            ],
          },
          {
            path: "/app/platform/products_orders",
            element: <ProductsOrders />,
          },
        ],
      },
      {
        path: "/app/tickets",
        children: [
          {
            path: "/app/tickets",
            element: <Tickets />,
          },
          {
            path: "/app/tickets/tickets/:ticket_id",
            element: <TicketDetails />,
          },
        ],
      },
      {
        path: "/app/payments",
        children: [
          {
            path: "/app/payments/cards",
            element: <Cards />,
          },
          {
            path: "/app/payments/order-recharge-cards",
            element: <OrderRechargeCards />,
          },
          {
            path: "/app/payments/issued_card",
            element: <IssuedCard />,
          },
          {
            path: "/app/payments/epayment_transactions",
            element: <EpaymentTransactions />,
          },
        ],
      },
      {
        path: "/app/settings",
        element: <Settings />,
      },
      // ------------- jolma ----------------
      {
        path: "/app/wholesale",
        children: [
          {
            path: "/app/wholesale/j_pos",
            element: <JPos />,
          },
          {
            path: "/app/wholesale/group",
            element: <JGroup />,
          },
          {
            path: "/app/wholesale/categories",
            element: <JCategories />,
          },
          {
            path: "/app/wholesale/items",
            element: <JItems />,
          },
          {
            path: "/app/wholesale/supplier",
            element: <JSupplier />,
          },
          {
            path: "/app/wholesale/invoice",
            element: <JInvoices />,
          },
          {
            path: "/app/wholesale/supplier_transactions/:supplier_id",
            element: <JTransaction />,
          },
          {
            path: "/app/wholesale/orders",
            element: <JOrder />,
          },
          {
            path: "/app/wholesale/order/:order_id",
            element: <JOrderDetails />,
          },
          {
            path: "/app/wholesale/reports",
            element: <JReport />,
          },
          {
            path: "/app/wholesale/safe",
            element: <JSafe />,
          },
          {
            path: "/app/wholesale/expense",
            element: <JExpense />,
          },
        ],
      },
    ],
  },
  {
    path: "/pos",
    element: <PosLayout />,
    children: [
      {
        path: "/pos/external-orders",
        element: <ExternalOrder />,
      },
    ],
  },
];

export default MainRoutes;
