import { forwardRef, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Divider,
  Stack,
  Box,
  Grid,
  Tooltip,
  IconButton,
  LinearProgress,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import dayjs from "dayjs";
import {
  JItems,
  setJItemStatusDialog,
  setJItemStatusListSearchBody,
} from "../../../../app/slices/jomla/itemsSlice";
import DateRangepicker from "../../../../helpers/dateRangePicker";
import { MdOutlineClear } from "react-icons/md";
import { JInvoice } from "../../../../app/slices/jomla/invoiceSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ItemHistoryDialog = () => {
  const dispatch = useDispatch();

  const _JItemHistory = useSelector((state) => state.JItems.jItemHistory);
  const search = useSelector((state) => state.JItems.jItemHistorySearchBody);

  const handleDialog = () => {
    dispatch(setJItemStatusDialog());
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "j_item_name",
      headerName: "اسم المادة",
      flex: 1,
    },
    {
      field: "count",
      headerName: "العدد",
      flex: 1,
      renderCell: (params) => (
        <Typography
          color={params.row.source_type === "order" ? "error" : "green"}
        >
          {params.row.count?.toLocaleString()}
        </Typography>
      ),
    },
    {
      field: "type",
      headerName: "الحركة",
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            cursor: "pointer",
            color: "blue",
            textDecoration: "underline",
          }}
          onClick={() => {
            if (params.row.source_type === "order") {
              window.open(
                `/app/wholesale/order/${params.row.source_id}`,
                "_blank"
              );
            } else {
              dispatch(JInvoice.getById(params.row.source_id));
            }
          }}
        >
          {params.row.source_type === "order" ? "بيع" : "شراء"}
        </Typography>
      ),
    },
    {
      field: "created_by",
      headerName: "بواسطة",
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "تاريخ الانشاء",
      flex: 1,
      renderCell: (params) => (
        <Stack spacing={0}>
          <Typography variant="body1">
            {dayjs(params.row.created_at).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="body1">
            {dayjs(params.row.created_at).format("hh:mm a")}
          </Typography>
        </Stack>
      ),
    },
  ];

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          قيمة المخزن الحالي : {_JItemHistory.total_amount?.toLocaleString()} 
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  useEffect(() => {
    dispatch(
      JItems.getJItemHistory(_JItemHistory.itemId, {
        start_date: search.start_date || dayjs().format("YYYY-MM-DD"),
        end_date: search.end_date || dayjs().format("YYYY-MM-DD"),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Dialog
      open={_JItemHistory.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      maxWidth="md"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography variant="h4" color={"purple"} gutterBottom component="div">
          {"حركات المادة"}
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  dispatch(
                    setJItemStatusListSearchBody({
                      start_date: dayjs(searchDate[0].startDate).format(
                        "YYYY-MM-DD"
                      ),
                      end_date: dayjs(searchDate[0].endDate).format(
                        "YYYY-MM-DD"
                      ),
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  dispatch(
                    setJItemStatusListSearchBody({
                      start_date: dayjs().format("YYYY-MM-01"),
                      end_date: dayjs().format("YYYY-MM-DD"),
                    })
                  );
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "66vh", width: "100%" }}>
              <DataGrid
                rows={_JItemHistory.data.map((item, index) => {
                  return {
                    ...item,
                    index: index + 1,
                  };
                })}
                columns={columns}
                rowCount={_JItemHistory.total}
                loading={_JItemHistory.loading}
                rowHeight={60}
                pagination
                paginationMode="client"
                selectionModel={[]}
                components={{
                  LoadingOverlay: LinearProgress,
                  Footer: CustomFooter,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ItemHistoryDialog;
